import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TeamDisplayGrid from './team-display-grid';
import { FormatListBulleted, PeopleAlt } from '@mui/icons-material';
import { BottomNavigationAction, BottomNavigation } from '@mui/material';
import { TeamDisplayTable } from './team-display-table';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    //padding: theme.spacing(1),
  },
}));

export default function LeaderboardTeamDialogue({open, teamData, handleCloseTeamDialogue}) {
  const [teamValue, setTeamValue] = useState(0);
  const [navValue, setNavValue] = React.useState(0);
    
  const handleClose = () => {
        handleCloseTeamDialogue(false);
    };

    useEffect(() => {
      if(teamData.players) {
        let valueSum = 0;
        teamData.players.forEach((player) => {
          if(player.transfer !== "in") {
            valueSum += Number(player.original_cost);
          }
        })
        setTeamValue(valueSum/10);
      }
    }, [teamData.players]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 , lineHeight: 0.8}} id="customized-dialog-title">
          {teamData.club_name}<span style={{fontSize:"8pt", color:"#999"}}><br/>Team Value: £{teamValue}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {navValue == 0 && (
            <TeamDisplayGrid teamData={teamData}/>
          )}
          {navValue == 1 && (
            <TeamDisplayTable teamData={teamData}/>
          )}
          <BottomNavigation
              showLabels
              value={navValue}
              onChange={(event, newValue) => {
                setNavValue(newValue);
              }}
              >
              <BottomNavigationAction label="Grid" icon={<PeopleAlt />} />
              <BottomNavigationAction label="List" icon={<FormatListBulleted />} />
          </BottomNavigation>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}