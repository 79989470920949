import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect } from 'react';
import TeamDisplayPlayer from './team-display-player';


export default function TeamDisplayGrid(teamData) {
  const [team, setTeam] = React.useState({GKP:[], DEF:[], MID:[], FWD:[], Bench:[]});
  const [goalkeepers, setGoalkeepers] = React.useState([]);
  const [defenders, setDefenders] = React.useState([]);
  const [midfielders, setMidfielders] = React.useState([]);
  const [forwards, setForwards] = React.useState([]);
  const [bench, setBench] = React.useState([]);
  
  useEffect(() => {
    const teamPlayers = {GKP:[], DEF:[], MID:[], FWD:[], Bench:[]};
    if(teamData.teamData.players === undefined) {
      return console.log("No players ");
    }
    teamData.teamData.players.forEach((player) => {
      if(player.transfer === "out") {
        teamPlayers.Bench.push(player);
      } else {
        teamPlayers[player.position_short].push(player);
      }
    });
    setTeam(teamPlayers);
    setGoalkeepers(teamPlayers.GKP);
    setDefenders(teamPlayers.DEF);
    setMidfielders(teamPlayers.MID);
    setForwards(teamPlayers.FWD);
    setBench(teamPlayers.Bench);
  }, [teamData.teamData.players]);

  return (
    <Box sx={{ flexGrow: 1, background: "url('https://fantasy.premierleague.com/static/media/pitch-default.dab51b01.svg') center top / 625px 460px no-repeat" }}>
      <Grid container spacing={2} minHeight={80}>
        {goalkeepers.map((player) => (
          <TeamDisplayPlayer key={player.id} player={player} />
          ))}
      </Grid>
      <Grid container spacing={2} minHeight={80}>
        {defenders.map((player) => (
          <TeamDisplayPlayer key={player.id} player={player} />
          ))}
      </Grid>
      <Grid container spacing={2} minHeight={120}>
        {midfielders.map((player) => (
          <TeamDisplayPlayer key={player.id} player={player} />
          ))}
      </Grid>
      <Grid container spacing={2} minHeight={100}>
        {forwards.map((player) => (
          <TeamDisplayPlayer key={player.id} player={player} />
          ))}
      </Grid>
      {bench.length > 0 && 
      <Grid container spacing={2} minHeight={100} sx={{backgroundColor:"#ccc", margin:"0px"}} >
        {bench.map((player) => (
          <TeamDisplayPlayer key={player.id} player={player} />
          ))}
      </Grid>
      }
    </Box>
  );
}