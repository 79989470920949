import React, { useState, useContext } from "react";
import { PageLayout } from "../components/page-layout";
import { LeaderboardContext } from "../contexts/leaderboard-context";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Fade } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import LeaderboardTeamDialogue from "../components/leaderboard-team-dialogue";

export const LeaderboardPage = () => {
  const {leaderboardData} = useContext(LeaderboardContext);
  const [isTeamDialogueOpen, setTeamDialogueIsOpen] = useState(false);
  const [selectedTeamData, setSelectedTeamData] = useState({});

  return (
    <PageLayout>
      <LeaderboardTeamDialogue open={isTeamDialogueOpen} teamData={selectedTeamData} key={selectedTeamData.club_name} handleCloseTeamDialogue={setTeamDialogueIsOpen}/>
      <TableContainer component={Paper}>
      {leaderboardData.length === 0 && (
        <Fade in={true} timeout={1000}>
          <Stack spacing={0.5}>
            <Skeleton variant="rounded" width={"100%"} height={30} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
          </Stack>
        </Fade>
      )}
      {leaderboardData.length > 0 && (
      <Table sx={{ minWidth: 390 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 189 }}>Club</TableCell>
            <TableCell>Wk</TableCell>
            <TableCell>Win</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboardData.map((row) => (
            <TableRow
              key={row.manager_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover
              onClick={() => {setSelectedTeamData(row); setTeamDialogueIsOpen(true)}}
            >
              <TableCell component="th" scope="row">
                <span>{row.club_name}</span>
                <br />
                {row.manager_name}
              </TableCell>
              <TableCell align="right">{row.week_score}</TableCell>
              <TableCell align="right">{row.window_score}</TableCell>
              <TableCell align="right">{row.total_score}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      )}
    </TableContainer>
    </PageLayout>
  );
};
