import React, { useEffect, useState, useContext } from "react";
import { LeaderboardContext } from "../contexts/leaderboard-context";
import { SiteVarsContext } from "../contexts/sitevars-context";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { getLoansResource } from "../services/loans.service";
import { getAuctionListResource } from "../services/auction.service";
import { Paper } from '@mui/material';
import { MakeLoan } from "../components/loans/make-loan";
import { AllLoans } from "../components/loans/all-loans";
import { MyLoans } from "../components/loans/my-loans";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import MoveUpIcon from '@mui/icons-material/MoveUp';

export const LoansPage = () => {
  const teamCodeLookup = {1:3, 2:7, 3:91, 4:94, 5:36, 6:8, 7:31, 8:11, 9:54, 10:40, 11:13, 12:14, 13:43, 14:1, 15:43, 16:17, 17:20, 18:6, 19:21, 20:39};
  const { isAuthenticated, user } = useAuth0();
  const {leaderboardData} = useContext(LeaderboardContext);
  const {siteVarsData} = useContext(SiteVarsContext);
  const [auctionListData, setAuctionListData] = useState([]);
  const [loansData, setLoansData] = useState([]);
  const [playersArr, setPlayersArr] = useState([]);
  const [navValue, setNavValue] = React.useState(0);

  useEffect(() => {
    leaderboardData.forEach((club) => {
        if(club["club_id"] === user['https://tlog-online.co.uk/club_id']) {
            setPlayersArr(club.players);
        }
    });
  }, [leaderboardData]);

  useEffect(() => {
    let isMounted = true;

    const getAuctionList = async () => {
      const { data, error } = await getAuctionListResource();
      if (!isMounted) { return; }
      if (data) { setAuctionListData(data); }
    };

    const getLoans = async () => {
      const { data, error } = await getLoansResource(user['https://tlog-online.co.uk/club_id'], siteVarsData[0].value);
      if (!isMounted) { return; }
      if (data) { 
        data.allLoans.sort((a,b) => b.startweek - a.startweek);
        setLoansData(data);
        }
    };

    getAuctionList();
    getLoans();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
        {navValue == 0 && (
            <MakeLoan loansData={loansData} clubPlayersArr={playersArr} auctionListData={auctionListData} leaderboardData={leaderboardData} />
        )}
        {navValue == 1 && (
            <MyLoans loansData={loansData} auctionListData={auctionListData} clubPlayersArr={playersArr} leaderboardData={leaderboardData} clubId={user['https://tlog-online.co.uk/club_id']} />
        )}
        {navValue == 2 && (
            <AllLoans loansData={loansData} clubPlayersArr={playersArr} auctionListData={auctionListData} leaderboardData={leaderboardData} />
        )}
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
            showLabels
            value={navValue}
            onChange={(event, newValue) => {
                setNavValue(newValue);
            }}
            >
            <BottomNavigationAction label="Make Loan" icon={<MoveUpIcon />} />
            <BottomNavigationAction label={"My Loans (" + (loansData.clubCount || 0) + ")"} icon={<PersonIcon />} />
            <BottomNavigationAction label="All Loans" icon={<GroupIcon />} />
        </BottomNavigation>
    </Paper>
    </PageLayout>
  );
};
