import React, { useEffect, useState, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {setPlayerLoanResource } from "../../services/loans.service";
import { SiteVarsContext } from "../../contexts/sitevars-context";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    //padding: theme.spacing(1),
  },
}));

export default function MakeLoanDialogue({open, playerData, auctionListData, loansData,  handleCloseMakeLoanDialogue}) {
  const teamCodeLookup = {"Arsenal":3, "Aston Villa":7, "Bournemouth":91, "Brentford":94, "Brighton":36, "Burnley":90, "Chelsea":8, "Crystal Palace":31, "Everton":11, "Fulham":54, "Liverpool":14, "Luton":102, "Man City":43, "Man Utd":1, "Newcastle":4, "Nott'm Forest":17, "Sheffield United":49, "Spurs":6, "West Ham":21, "Wolves":39};
  const { user } = useAuth0();
  const {siteVarsData} = useContext(SiteVarsContext);

  const handleClose = () => {
    handleCloseMakeLoanDialogue(false);
  };

  const handleMakeLoan = async (playerIn) => {
    //setSiteVarsUpdating(true);
    const clubId = user['https://tlog-online.co.uk/club_id'];
    const windowId = Number(siteVarsData[0].value);
    const startWeek = Number(siteVarsData[5].value) + 1;
    await setPlayerLoanResource(clubId, windowId, startWeek, playerIn.player_id, playerData.player_id);
    debugger;
    loansData.allLoans.push({clubid: clubId, playerin: playerIn.player_id, playerout: playerData.player_id, startweek: startWeek, windowid: windowId});
    loansData.allLoans.sort((a,b) => b.startweek - a.startweek);
    loansData.clubCount++;
    handleClose();
  };


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 , lineHeight: 0.8}} id="customized-dialog-title">
          Make Loan<span style={{fontSize:"8pt", color:"#999"}}><br/>Out: {playerData.last_name}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 340 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{padding:"10px"}}></TableCell>
              <TableCell padding='none'>Player</TableCell>
              <TableCell sx={{padding:"5px"}}>Cost</TableCell>
              <TableCell sx={{padding:"5px"}}>Total</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctionListData.map((row) => (
              row.position_short == playerData.position_short && row.original_cost <= playerData.original_cost && (
              <TableRow key={row.prem_player_id}>
                <TableCell sx={{padding:"10px"}}><Avatar sx={{width:24, height:24}} src={`https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${teamCodeLookup[row.team_name]}${row.position_short === "GKP" ? "_1" : ""}-66.webp`} /></TableCell>
                <TableCell padding='none'><span>{row.last_name}</span>
                  <br />
                  <span style={{fontWeight: 'bold'}}>{row.position_short}</span> {row.team_name_short}</TableCell>
                <TableCell sx={{padding:"5px"}}>{row.original_cost}</TableCell>
                <TableCell sx={{padding:"5px"}}>{row.total_points}</TableCell>
                <TableCell><Button variant="outlined" size="small" onClick={() => {handleMakeLoan(row);}}>Loan</Button></TableCell>
              </TableRow>
              )
            ))}
          </TableBody>
        </Table>
        
      </TableContainer>
      </BootstrapDialog>
    </div>
  );
}