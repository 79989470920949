import React, { useState, useContext, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Fade } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { LeaderboardContext } from "../contexts/leaderboard-context";
import { getAuctionListResource } from "../services/auction.service";

export const AuctionPage = () => {
  const {leaderboardData} = useContext(LeaderboardContext);
  const [auctionData, setAuctionData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const getAuctionData = async () => {
      const { data, error } = await getAuctionListResource();
      if (!isMounted) { return; }
      debugger;
      if (data) { setAuctionData(data); }
    };

    getAuctionData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
      <TableContainer component={Paper}>
      {leaderboardData.length === 0 && (
        <Fade in={true} timeout={1000}>
          <Stack spacing={0.5}>
            <Skeleton variant="rounded" width={"100%"} height={30} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
          </Stack>
        </Fade>
      )}
      {auctionData.length > 0 && (
      <Table sx={{ minWidth: 390 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Player</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Team</TableCell>
            <TableCell>Points</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboardData.map((row) => (
            <TableRow
              key={row.player_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right">{row.last_name}, {row.first_name}</TableCell>
              <TableCell align="right">{row.position_id}</TableCell>
              <TableCell align="right">{row.team_code}</TableCell>
              <TableCell align="right">{row.now_points}</TableCell>
              <TableCell align="right">{row.original_cost}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      )}
    </TableContainer>
    </PageLayout>
  );
};
