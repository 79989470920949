import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export const AllLoans = (props) => {

  const getPlayerName = (playerId) => {
    let playerName = "";
    
    props.leaderboardData.forEach((club) => {
        club.players.forEach((player) => {
            if(player.player_id === playerId) {
                playerName = player.last_name;
                return;
            }
        });
    });

    props.auctionListData.forEach((player) => {
        if(player.player_id === playerId) {
            playerName = player.last_name;
            return;
        }
    });
    return playerName;
  }

  const getClubName = (clubId) => {
    let clubName = "";
    props.leaderboardData.forEach((club) => {
        if(club.club_id === clubId) {
            clubName = club.club_name;
        }
    });
    return clubName;
  }


  return (
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 390 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Club</TableCell>
            <TableCell >Player</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loansData.allLoans.map((row) => (
              <TableRow key={row.playerin}>
                <TableCell >{getClubName(row.clubid)}</TableCell>
                <TableCell >In: <span style={{color:"#666"}}>{getPlayerName(row.playerin)}</span><br />
                Out: <span style={{color:"#666"}} >{getPlayerName(row.playerout)}</span><br />
                Week: <span style={{color:"#666"}} >{row.startweek}</span> Window: <span style={{color:"#666"}} >{row.windowid}</span></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>      
    </TableContainer>
  );
};
