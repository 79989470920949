import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export const MyLoans = (props) => {

  const clubId = props.clubId;
  const getPlayerName = (playerId) => {
    let playerName = "";
    props.leaderboardData.forEach((club) => {
        club.players.forEach((player) => {
            if(player.player_id === playerId) {
                playerName = player.last_name;
                return;
            }
        });
    });

    props.auctionListData.forEach((player) => {
        if(player.player_id === playerId) {
            playerName = player.last_name;
            return;
        }
    });
    return playerName;
  }

  return (
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 390 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell >In</TableCell>
            <TableCell >Out</TableCell>
            <TableCell >Week</TableCell>
            <TableCell >Window</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loansData.allLoans.map((row) => (
            row.clubid === props.clubId && (
              <TableRow key={row.playerin}>
                <TableCell >{getPlayerName(row.playerin)}</TableCell>
                <TableCell >{getPlayerName(row.playerout)}</TableCell>
                <TableCell >{row.startweek}</TableCell>
                <TableCell >{row.windowid}</TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>      
    </TableContainer>
  );
};
